import Grid from "@mui/material/Grid"
import {BACKEND_URL} from "../../data/config";
import React, {useEffect, useState} from "react";
import {useGlobalContext} from "../../contexts/global_context";
import LoadingButton from "@mui/lab/LoadingButton";

const ImagePicker = (
  {
    totalCount,
    minCount,
    allImages,
    selectedImages,
    setSelectedImages,
    square,
    pageSize,
    getMoreImages,
    hasMoreImages,
  }
) => {
  let [showingImages, setShowingImages] = useState([])
  let [notShowingImages] = useState([])
  let {sendingAjaxUrls, showAlert} = useGlobalContext()

  useEffect(() => {
    for (let image of allImages) {
      if (!showingImages.find(item => item.id === image.id)) {
        notShowingImages.push(image)
      }
    }

    // load images if nothing is showing
    loadImages()
  }, [allImages])

  const loadImages = () => {
    if (notShowingImages.length) {
      let newShowingImages = Object.assign([], showingImages)

      for (let i = 0; i < pageSize; i++) {
        if (notShowingImages.length) {
          newShowingImages.push(notShowingImages.pop())
        }
      }

      setShowingImages(newShowingImages)
    } else {
      // get posts from server
      getMoreImages()
    }
  }

  const handleClick = (id) => {
    let newSelectedImages = []

    let image = selectedImages.find((item) => {
      return item.id === id
    })

    if (image) {
      newSelectedImages = selectedImages.filter((item) => {
        return item.id !== id
      })
    } else {
      newSelectedImages = [
        ...selectedImages,
        {
          id: id,
          count: 0,
        },
      ]
    }

    // calculate count
    let count = Math.floor(totalCount / (newSelectedImages.length))
    let difference = totalCount - (count * (newSelectedImages.length))

    // check min quantity
    if (count < minCount) {
      showAlert(
        `The minimum quantity is ${minCount} per post for this package.`,
        'warning',
      )
    } else {
      for (let [index, item] of newSelectedImages.entries()) {
        item.count = index === 0 ? count + difference : count
      }

      setSelectedImages(newSelectedImages)
    }
  }

  const getImageCount = (id, totalCount, selectedImages) => {
    let image = selectedImages.find((item) => {
      return item.id === id
    })

    if (image) {
      return image.count
    } else {
      return 0
    }
  }

  return (
    <div className={'image-picker-container'}>
      <Grid container justifyContent={'center'}>
        {showingImages.map(item => {
          return (
            <Grid item xs={4} sm={3} md={3} key={item.id}>
              <ImagePickerItem
                image={item}
                count={getImageCount(item.id, totalCount, selectedImages)}
                handleClick={handleClick}
                square={square}
              />
            </Grid>
          )
        })}

        <LoadingButton
          sx={{
            margin: '10px 0 0 0',
          }}
          color={'primary'}
          size={'large'}
          fullWidth={true}
          variant="outlined"
          onClick={loadImages}
          disabled={!hasMoreImages && !notShowingImages.length}
          loading={sendingAjaxUrls.includes(`${BACKEND_URL}/api/v1/socials/posts/`)}
        >
          Load More
        </LoadingButton>
      </Grid>
    </div>
  )
}

const ImagePickerItem = ({image, count, handleClick, square}) => {
  return (
    <div className={'image-picker-item'} onClick={() => {
      handleClick(image.id)
    }}>
      {
        count ? <div className={'image-picker-item-overlay'}>
          <h2 style={{verticalAlign: 'middle'}}>{count}</h2>
        </div> : ''
      }

      <img
        src={`${BACKEND_URL}/api/v1/socials/url-router/?url=${encodeURIComponent(image.image)}`}
        className={'image-picker-item-img'}
        alt={''}
        style={square ? {aspectRatio: '4 / 5'} : {}}
      />
    </div>
  )
}

export default ImagePicker
