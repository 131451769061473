import {Swiper, SwiperSlide, useSwiper} from "swiper/react"
import 'swiper/swiper-bundle.css'
import {Pagination, Navigation} from 'swiper/modules'
import {Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import PricingBox from "./PricingBox"
import {useParams} from "react-router-dom"
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {getIconBySocial} from "../../utils"
import {useEffect, useRef, useState} from "react";
import {useGlobalContext} from "../../contexts/global_context";
import {BACKEND_URL} from "../../data/config";
import {Element} from 'react-scroll'

// SwiperCore.use([Pagination, Navigation])

const Pricing = ({showTabs = false, groupedCategories = []}) => {
  let {sendAjax} = useGlobalContext()
  let [packages, setPackages] = useState([])
  let categorySlugUrlParam = useParams().categorySlug
  let [categorySlug, setCategorySlug] = useState(categorySlugUrlParam || '')
  let sliderRef = useRef()
  let [tab, setTab] = useState('')
  const swiper = useSwiper()

  useEffect(() => {
    setCategorySlug(categorySlugUrlParam)
  }, [categorySlugUrlParam])

  useEffect(() => {
    if (!categorySlugUrlParam && groupedCategories && groupedCategories.length) {
      if (!tab) {
        setTab(groupedCategories[0].social_display.toLowerCase())
      }

      let group = groupedCategories.find((item) => {
        return item.social_display.toLowerCase() === tab
      })

      if (group) {
        setCategorySlug(group.categories[0].slug)
      }
    }

  }, [tab, groupedCategories])

  useEffect(() => {
    if (categorySlug) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/packages/`,
        method: 'get',
        data: {
          page_size: 1000,
          category__slug: categorySlug,
        },
      }).then(r => {
        if (r.data.status_code) {
          setPackages(r.data.data.results)
          try {
            setTimeout(() => {
              sliderRef.current.swiper.slideToLoop(0)
            }, 200)

          } catch (e) {
          }
        }
      })
    }
  }, [categorySlug])

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <div className={'pricing'}>
      <div className={'pricing-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'pricing-head'}>
              <Typography variant={'h4'} component={'h1'}>
                {showTabs ? 'Best Selling Services' : 'Pricing Tailored for You'}
              </Typography>

              <br/>

              <Typography variant="p" color={'text.secondary'}>
                We provide you with the best packages that competitively priced.
                You'll find something that suits you, no matter if you're
                looking to buy packages for your personal or a company account.
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Element name="pricing-scroll-element"/>

        {showTabs &&
          tab &&
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={'pricing-tabs'}>
                <Tabs value={tab} onChange={handleChangeTab} centered>
                  {groupedCategories.map((group, index) => {
                    return (
                      <Tab
                        key={index}
                        value={group.social_display.toLowerCase()}
                        icon={getIconBySocial(group.social_display.toLowerCase())}
                        label={group.social_display}
                      />
                    )
                  })}
                </Tabs>
                <br/>
              </div>
            </Grid>
          </Grid>
        }

        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            {
              packages.length &&
              <Swiper
                modules={[Navigation, Pagination]}
                ref={sliderRef}
                slidesPerGroup={1}
                loop={true}
                loopfillgroupwithblank="false"
                pagination={{
                  "clickable": true,
                }}
                navigation={true}
                className="pricing-swiper"
                breakpoints={{
                  600: {
                    slidesPerView: 1,
                  },
                  900: {
                    slidesPerView: 3,
                  },
                }}
              >
                {packages.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <PricingBox item={item}/>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            }

          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Pricing
